import { ComponentClientSpecMapEntry } from '@wix/editor-platform-sdk-types';

import { EditorPlatformMigration } from '../EditorPlatformMigration';

import {
  addApplications,
  isMembersAreaInstalled,
} from '@wix/members-area-integration-kit';
import { GROUPS_IN_MA } from '../../constants';

const GROUPS_WIDGET_ID = 'a7dcdfcb-8abd-4008-af19-fed5fcd12b40';

/**
 * Install groups in members area widget
 */
export default class extends EditorPlatformMigration {
  version = 1;
  name = 'install-groups-in-ma';

  async migrate(component: ComponentClientSpecMapEntry) {
    if (!this.flowAPI.experiments.enabled('specs.groups.GroupsInMAMigration')) {
      return false;
    }

    if (component.widgetId === GROUPS_WIDGET_ID) {
      try {
        if (await isMembersAreaInstalled(this.sdk)) {
          await addApplications(this.sdk, [GROUPS_IN_MA]);
        }
        return true;
      } catch (e) {}
    }

    return false;
  }
}
